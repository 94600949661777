import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import SEO from "../components/seo";
import mq from "../util/mediaQueries";
import { useLocation } from "@reach/router";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
} from "react-share";
import { css } from "@emotion/core";
import Img from "gatsby-image";

const Article = styled.div`
  padding: 0 5px;
  ${mq[0]} {
    max-width: 320px;
  }
  ${mq[2]} {
    max-width: 720px;
  }
`;

const ArticleCategory = styled.span`
  line-height: 1.1;
  font-size: 12px;
  color: blue;
`;

const ArticleTitle = styled.h1`
  line-height: 1.1;
  font-size: 26px;
`;

const ArticleImage = styled.div`
  margin: 10px 0;
`;

const ArticleMeta = ({ title, value }) => (
  <div css={{ fontWeight: 600 }}>
    <span css={{ fontWeight: "lighter" }}>{title}: </span>
    {value}
  </div>
);

const ArticleBody = styled.div`
  line-height: 1.6;
  font-size: 20px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding: 10px 0;
  color: #2e2e2e;
  a {
    color: blue;
    word-break: break-all;
  }
  img {
    display: block;
    max-width: 100%;
  }
`;

const SplitView = styled.div`
  display: flex;
  .full {
    flex: 1;
  }
  .right {
    text-align: right;
  }
`;

const YoutubeVideo = ({ title, url }) => (
  <iframe
    css={css`
      ${mq[0]} {
        width: 320px;
        height: 200px;
      }
      ${mq[2]} {
        width: 720px;
        height: 400px;
      }
    `}
    title={title}
    src={url}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
);

export default function ArticleTemplate({ data }) {
  const { pathname } = useLocation();
  const post = data.markdownRemark;
  const url = `${data.site.siteMetadata.siteUrl}${pathname}`;

  return (
    <Layout>
      <SEO
        article={true}
        title={post.frontmatter.title}
        description={post.frontmatter.tagline}
        image={post.frontmatter.image.childImageSharp.fluid.src}
      />

      <Article>
        <ArticleCategory>{post.fields.category}</ArticleCategory>
        <ArticleTitle>{post.frontmatter.title}</ArticleTitle>
        {post.frontmatter.youtube ? (
          <YoutubeVideo
            title={post.frontmatter.title}
            url={post.frontmatter.youtube}
          />
        ) : (
          post.frontmatter.image && (
            <ArticleImage>
              <Img
                fluid={post.frontmatter.image.childImageSharp.fluid}
                alt={post.frontmatter.imageAlt}
              />
            </ArticleImage>
          )
        )}

        <SplitView>
          <div className="full">
            <ArticleMeta title="By" value={post.frontmatter.author} />
            <ArticleMeta title="Published" value={post.frontmatter.date} />
          </div>
          <div className="right">
            <FacebookShareButton url={url}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            &nbsp;
            <TwitterShareButton url={url}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </div>
        </SplitView>

        <ArticleBody>
          <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
        </ArticleBody>
      </Article>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        category
      }
      frontmatter {
        youtube
        title
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageAlt
        tagline
        author
        date(formatString: "MMMM DD, YYYY")
      }
    }

    site {
      siteMetadata {
        siteUrl: url
      }
    }
  }
`;
